import { ref } from 'vue'
import axiosInstance from '../plugins/axios';

export default function useStars() {
    const stars = ref([])
    const star = ref([])
    const errors = ref('')
    const infoPaginate = ref({})


    const getStars = async (page = 1, dateValue = 'mounth', ) => {
        let response = await axiosInstance.get(`/api/stars?page=${page}&date=${dateValue}`)
        stars.value = response.data.data;
        
        infoPaginate.value = {
            current: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
        }
     
    }

    const getStar = async (id) => {
        let response = await axios.get('/api/stars/' + id)
        star.value = response.data.data;
    }

    const storeStar = async (data) => {
        errors.value = ''
        try {
            await axios.post('/api/stars', data)
            await router.push({name: 'stars.index'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
        }
    }

    const updateStar = async (id) => {
        errors.value = ''
        try {
            await axios.put('/api/stars/' + id, star.value)
            await router.push({name: 'stars.index'})
        } catch (e) {
            if (e.response.status === 422) {
               errors.value = e.response.data.errors
            }
        }
    }

    const destroyStar = async (id) => {
        await axios.delete('/api/stars/' + id)
    }


    return {
        stars,
        star,
        errors,
        infoPaginate,
        getStars,
        getStar,
        storeStar,
        updateStar,
        destroyStar
    }
}
