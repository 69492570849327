<template>
    <template v-if="videos.length">
        <template v-for="video in videos" :key="video.id">
            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                <VideoItem :video="video" />
            </div>
        </template>
        <div class="row mt-3">
            <pagination :next='next' :prev='prev' :current="infoPaginate.current" :total="infoPaginate.last_page" />
        </div>
    </template>

</template>

<script>

import useVideos from "../../composables/videos";
import { onMounted, watch } from "vue";
import VideoItem from './VideoItem';
import Pagination from '../utilities/Pagination'
import { useRoute } from 'vue-router';

export default {
    name: "VideoList",
    props: {
        dateSelect: String,
    },
    components: {
        VideoItem,
        Pagination
    },
    setup(props) {
        const { videos, getVideos, infoPaginate } = useVideos();
        const route = useRoute();
        const page = parseInt(route.query.page) || 1

        // Get page from query params
        onMounted(() => getVideos(page))

        // watch(() => props.dateSelect, (dateValue) => {
        //     console.log(dateValue);
        //     // Page is 1 and dataValue will be received
        //     // From props
        //     getVideos(1, dateValue)
        // });

        return {
            videos,
            infoPaginate,
            getVideos
        }
    },
    methods: {
        prev() {
            this.infoPaginate.current--
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getVideos(this.infoPaginate.current, this.dateSelect)
            // Change route with query params
            if (this.infoPaginate.current > 1) {
                this.$router.push({ query: { page: this.infoPaginate.current } })
            } else {
                // Delete query params page
                this.$router.push({})
            }


        },
        next() {
            this.infoPaginate.current++
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getVideos(this.infoPaginate.current, this.dateSelect)
            if (this.infoPaginate.current > 1) {
                this.$router.push({ query: { page: this.infoPaginate.current } })
            } else {
                this.$router.push({})
            }
        },

    }
}   
</script>

<style scoped></style>