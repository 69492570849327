<template>
    <div class="card mb-5">
        <div class="card-body">
            <h5 class="card-title">
                {{ star.name }} - {{ star.videos_count }} Videos
            </h5>
        </div>
    </div>
    <template v-if="videos.length">
        <div class="row">

            <template v-for="video in videos" :key="video.id">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <VideoItem :video="video" />
                </div>
            </template>
            <div class="row mt-3">
                <pagination :next='next' :prev='prev' :current="infoPaginate.current" :total="infoPaginate.last_page" />
            </div>
        </div>
    </template>

</template>

<script>

import useVideos from "../../composables/videos";
import { onMounted, watch } from "vue";
import VideoItem from '../videos/VideoItem';
import Pagination from '../utilities/Pagination'
import { useRoute } from 'vue-router'

export default {
    name: "StarsSpecific",
    props: {
        dateSelect: String,
    },
    components: {
        VideoItem,
        Pagination
    },
    setup(props) {
        const { videos, star, getVideosByStar, infoPaginate } = useVideos()
        const route = useRoute()
        const starId = route.params.starId
        const page = parseInt(route.query.page) || 1

        onMounted(() => getVideosByStar(starId, page))

        return {
            videos,
            star,
            infoPaginate,
            getVideosByStar,
            starId
        }
    },
    methods: {
        prev() {
            this.infoPaginate.current--
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getVideosByStar(this.categoryId, this.infoPaginate.current)
            // Change route with query params
            if (this.infoPaginate.current > 1) {
                this.$router.push({ query: { page: this.infoPaginate.current } })
            } else {
                // Delete query params page
                this.$router.push({})
            }
        },
        next() {
            this.infoPaginate.current++
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getVideosByStar(this.categoryId, this.infoPaginate.current)
            // Change route with query params
            if (this.infoPaginate.current > 1) {
                this.$router.push({ query: { page: this.infoPaginate.current } })
            } else {
                this.$router.push({})
            }
        }
    }
}   
</script>
