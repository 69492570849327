require('./bootstrap');

require('alpinejs');

import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axiosInstance  from '../js/plugins/axios';

// Import jquery globally
import $ from 'jquery';
window.$ = window.jQuery = $;

$(window).on('load', function () {
    $('#status').fadeOut();
    $('#preloader').delay(350).fadeOut('slow');
});

// Import Font Awesome
import '@fortawesome/fontawesome-free/css/all.min.css';

import 'bootstrap/dist/css/bootstrap.min.css';

import '../css/app.min.css';

import Search from './components/utilities/Search';

const app = createApp(App);

app.use(router);

app.component('search', Search);

app.config.globalProperties.axios = { ...axiosInstance }

app.mount('#app')

