import { ref } from 'vue'
import { useRouter } from 'vue-router';
import axiosInstance from '../plugins/axios';


export default function useCategory() {
    const categories = ref([])
    const category = ref([])
    const router = useRouter()
    const errors = ref('')
    const infoPaginate = ref({})

    const getCategories =   async (page = 1, dateValue = 'mounth', ) => {
        let response = await axiosInstance.get(`/api/categories?page=${page}&date=${dateValue}`)
        categories.value = response.data.data;
       
        infoPaginate.value = {
            current: response.data.current_page,
            last_page: response.data.last_page,
            per_page: response.data.per_page,
        }
    }

    const getCategory = async (id) => {
        let response = await axiosInstance.get('/api/categories/' + id)
        category.value = response.data.data;
    }

    const storeCategory = async (data) => {
        errors.value = ''
        try {
            await axios.post('/api/categories', data)
            await router.push({name: 'categories.index'})
        } catch (e) {
            if (e.response.status === 422) {
                errors.value = e.response.data.errors
            }
        }
    }

    const updateCategory = async (id) => {
        errors.value = ''
        try {
            await axios.put('/api/categories/' + id, category.value)
            await router.push({name: 'categories.index'})
        } catch (e) {
            if (e.response.status === 422) {
               errors.value = e.response.data.errors
            }
        }
    }

    const destroyCategory = async (id) => {
        await axios.delete('/api/categories/' + id)
    }


    return {
        categories,
        category,
        errors,
        infoPaginate,
        getCategories,
        getCategory,
        storeCategory,
        updateCategory,
        destroyCategory
    }
}
