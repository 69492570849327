<template>
    <div class="col-12">
        <ul class="pagination pagination-rounded justify-content-center mt-2 mb-5">
            <li :class="{ 'disabled': current - 1 <= 0 }" class="page-item">
                <a class="page-link" @click="prev">
                    <i style="font-size: 12px;" class="fas fa-chevron-left"></i>
                </a>
            </li>
            <li class="page-item active">
                <a class="page-link">{{ current }}</a>
            </li>
            <li v-show="current + 1 <= total" class="page-item">
                <a class="page-link" @click="next">{{ current + 1 }}</a>
            </li>
            <li v-show="current + 1 <= total" :class="{ 'disabled': current + 1 > total }" class="page-item">
                <a class="page-link" @click="next">
                    <i style="font-size: 12px;" class="fas fa-chevron-right"></i>
                </a>
            </li>
        </ul>
    </div>

</template>

<script>

export default {
    name: "Pagination",
    components: {
        // paginationItem,
    },
    props: {
        prev: Function,
        current: Number,
        next: Function,
        total: Number,
    },
    data() {
        return {
            numberOfElements: 5,
        }
    }
}
</script>
