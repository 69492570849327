<template>
    <Preloader />
    <router-view :key="$route.fullPath" />
</template>

<script>
import Preloader from './components/utilities/Preloader';

export default {
    components: {
        Preloader,
    },
};
</script>