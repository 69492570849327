<template>
    <div class="container-fluid m-0 p-0 container-filters">
        <div class="dropdown">
            <select
                @change="changeDateSelect"
                name="filer-views"
                class="form-select form-select-sm select-views"
                aria-label="Default select example"
            >
                <option value="today">Today</option>
                <option value="week">This week</option>
                <option value="mounth">This mounth</option>
                <option value="alltime">All time</option>
            </select>
        </div>
    </div>
</template>

<script>
export default {
    name: "Filters",
    props: {
        dateSelect: String,
        changeDateSelect: Function,
    },
};
</script>

<style scoped>
.dropdown {
    width: 170px;
}
.container-filters {
    display: flex;
    align-items: center;
    /* justify-content: flex-end; */
}
.filer-views {
    padding-right: 5px;
}
.select-views {
    border: 0;
    background-color: #404040 !important;
}
</style>
