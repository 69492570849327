<template>
    <div class="accordion accordion-flush" id="accordionFlushRelatedVids">
        <div class="accordion-item">
            <h2 class="accordion-header" id="accordionRelatedVids">
                <button class="accordion-button fw-medium" type="button" data-bs-toggle="collapse"
                    data-bs-target="#relatedVids" aria-expanded="true" aria-controls="relatedVids">
                    Related Videos
                </button>
            </h2>
            <div id="relatedVids" class="accordion-collapse collapse show" aria-labelledby="accordionRelatedVids"
                data-bs-parent="#accordionFlushRelatedVids">
                <div class="accordion-body text-muted row">
                    <template v-if="videos.length">
                        <template v-for="video in videos" :key="video.id">
                            <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                                <VideoItem :video="video" />
                            </div>
                        </template>
                        <button type="button" @click="showMore" class="btn btn-secondary container-fluid w-100">Show
                            More</button>
                    </template>
                    <template v-else>
                        <div class="col-xl-12">
                            <div class="alert alert-warning" role="alert">
                                No videos found
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VideoItem from '../videos/VideoItem'
import { onMounted, watch } from "vue";
import useVideos from "../../composables/videos";

export default {
    props: {
        videoId: {
            required: true,
        },
    },
    components: {
        VideoItem,
    },
    setup(props) {
        const { errors, videos, getRelatedVideosByVideoId } = useVideos()
        onMounted(() => getRelatedVideosByVideoId(props.videoId))

        watch(() => props.videoId, (newVal) => {
            getRelatedVideosByVideoId(newVal)
        })

        return {
            errors,
            videos,
            getRelatedVideosByVideoId
        }
    },
    methods: {
        showMore(e) {
            e.target.style.display = 'none';
            this.getRelatedVideosByVideoId(this.videoId, true);
        }
    }
}
</script>

<style></style>