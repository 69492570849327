// src/plugins/axios.js
import axios from 'axios';
import { usePreloader } from '../components/utilities/Preloader.vue';

const axiosInstance = axios.create({
    withCredentials: true,
});

const { isLoading } = usePreloader();

// Add a request interceptor
axiosInstance.interceptors.request.use(function (config) {
    // Do something before request is sent
    isLoading.value = true;
    return config;
}, function (error) {
    // Do something with request error
    isLoading.value = false;
    return Promise.reject(error);
});

// Add a response interceptor
axiosInstance.interceptors.response.use(function (response) {
    // Do something with response data
    isLoading.value = false;
    
    return response;
}, function (error) {
    // Do something with response error
    isLoading.value = false;
    return Promise.reject(error);
});

export default axiosInstance;
