<template>
    <div class="row">
        <div class="col-xl-12">
            <single-video-item :video="video" />
        </div>
        <div class="col-xl-12">
            <related-videos :videoId="videoId" />
        </div>
    </div>
</template>
<script>
import useVideos from "../../composables/videos";
import { onMounted, watch } from "vue";
import SingleVideoItem from "./SingleVideoItem";
import RelatedVideos from "./RelatedVideos";
import { useRoute } from "vue-router";

export default {
    props: {
        videoId: {
            required: true,
            type: String,
        },
    },
    components: {
        SingleVideoItem,
        RelatedVideos,
    },
    setup(props) {
        const { video, getVideo } = useVideos();
        const route = useRoute();
        const loadVideo = () => {
            getVideo(props.videoId);
        };

        onMounted(() => {
            loadVideo();
        });

        watch(() => route.params, (newParams, oldParams) => {
            console.log('Params changed:', newParams);
            // Handle the params update here
            fetchData(newParams.id);
        }
        );

        return {
            video,
        };
    },
};
</script>
