<template>
    <div class="card mb-5">
        <div class="card-body">
            <h5 class="card-title">
                Staruri
            </h5>
        </div>
    </div>
    <template v-if="stars.length">
        <div class="row">
            <template v-for="star in stars" :key="star.id">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <StarItem :star="star" />
                </div>
            </template>
            <div class="row mt-3">
                <pagination :next='next' :prev='prev' :current="infoPaginate.current" :total="infoPaginate.last_page" />
            </div>
        </div>
    </template>
</template>

<script>

import useStars from "../../composables/stars";
import { onMounted, watch } from "vue";
import StarItem from './StarItem';
import Pagination from '../utilities/Pagination'
import { useRoute } from 'vue-router';

export default {
    name: "StarsIndex",
    components: {
        StarItem,
        Pagination
    },
    setup() {
        const { stars, getStars, infoPaginate } = useStars()
        const route = useRoute();
        const page = parseInt(route.query.page) || 1

        onMounted(() => getStars(page))

        return {
            stars,
            infoPaginate,
            getStars
        }

    },
    methods: {
        prev() {
            this.infoPaginate.current--
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getStars(this.infoPaginate.current, this.dateSelect)
            // Change route with query params
            if (this.infoPaginate.current > 1) {
                this.$router.push({ query: { page: this.infoPaginate.current } })
            } else {
                // Delete query params page
                this.$router.push({})
            }
        },
        next() {
            this.infoPaginate.current++
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getStars(this.infoPaginate.current, this.dateSelect)
            // Change route with query params
            if (this.infoPaginate.current > 1) {
                this.$router.push({ query: { page: this.infoPaginate.current } })
            } else {
                this.$router.push({})
            }

        }
    }
}
</script>

<style></style>