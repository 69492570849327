<template>
    <!--Card-->
    <router-link :to="{ name: 'single.star', params: { starId: star.id } }" class="text-decoration-none text-dark ">
        <div class="card mb-3">
            <div class="card-body text-center">
                <div class="">
                    <img :src="star.imageUrl" style="max-height: 200px;" alt="200x200" data-holder-rendered="true"
                        class="img-fluid">
                </div>

                <h4 class="card-title mt-1 font-size-16">{{ star.name }} - {{ star.videos_count }}</h4>
            </div>
        </div>
    </router-link>
</template>


<script>
export default {
    props: {
        star: Object,
    },
    methods: {
        imageUrlAlt(event) {
            event.target.src = "http://127.0.0.1:8000/storage/default/stars/default.jpg"
            console.clear();
        }
    }
}
</script>
