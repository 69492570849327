import { createRouter, createWebHistory } from "vue-router";
import { usePreloader } from '../components/utilities/Preloader.vue';

import 'bootstrap';

import FrontpageIndex from '../components/frontpage/FrontpageIndex'
import VideosIndex from '../components/videos/VideosIndex'
import VideosCreate from '../components/videos/VideosCreate'
import VideosEdit from '../components/videos/VideosEdit'
import SingleVideo from '../components/frontpage/SingleVideo'
import CategoriesIndex from '../components/categories/CategoriesIndex'
import StarsIndex from '../components/stars/StarsIndex'
import StarsSpecific from '../components/stars/StarsSpecific'
import CategorySpecific from '../components/categories/CategorySpecific'
import SearchIndex from '../components/utilities/SearchIndex'

const routes = [
    {
        path: '/',
        name: 'home',
        component: FrontpageIndex,
    },
    {
        path: '/video/:videoId/',
        name: 'single.video',
        component: SingleVideo,
        props: true
    },
    {
        path: '/search',
        name: 'search-results',
        component: SearchIndex,
        props: true
    },
    {
        path: '/categorii',
        name: 'categorii',
        component: CategoriesIndex
    },
    {
        path: '/staruri',
        name: 'stars',
        component: StarsIndex
    },
    {
        path: '/categorie/:categoryId/',
        name: 'single.category',
        component: CategorySpecific
    },
    {
        path: '/star/:starId/',
        name: 'single.star',
        component: StarsSpecific
    },
    {
        path: '/admin/videos',
        name: 'videos.index',
        component: VideosIndex
    },
    {
        path: '/admin/videos/create',
        name: 'videos.create',
        component: VideosCreate
    },
    {
        path: '/admin/videos/:id/edit',
        name: 'videos.edit',
        component: VideosEdit,
        props: true
    }
]


const router = createRouter({
    history: createWebHistory(),
    routes,
  });

const { isLoading } = usePreloader();

router.beforeEach((to, from, next) => {
    isLoading.value = true;
    next();
  });
  
router.afterEach(() => {
    isLoading.value = false;
});

export default router;

