<template>
    <div class="card mb-5">
        <div class="card-body">
            <h5 class="card-title">
                Categorii
            </h5>
        </div>
    </div>
    <template v-if="categories.length">
        <div class="row">
            <template v-for="category in categories" :key="category.id">
                <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                    <CategoryItem :category="category" />
                </div>
            </template>
            <div class="row mt-3">
                <pagination :next='next' :prev='prev' :current="infoPaginate.current" :total="infoPaginate.last_page" />
            </div>
        </div>

    </template>
</template>

<script>

import useCategories from "../../composables/categories";
import { onMounted, watch } from "vue";
import CategoryItem from './CategoryItem';
import Pagination from '../utilities/Pagination'
import { useRoute } from 'vue-router';

export default {
    name: "CategoriesIndex",
    components: {
        CategoryItem,
        Pagination
    },
    setup() {
        const { categories, getCategories, infoPaginate } = useCategories()
        const route = useRoute();
        const page = parseInt(route.query.page) || 1
        onMounted(() => getCategories(page))

        return {
            categories,
            infoPaginate,
            getCategories
        }
    },
    methods: {
        prev() {
            this.infoPaginate.current--
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getCategories(this.infoPaginate.current, this.dateSelect)
            // Change route with query params
            if (this.infoPaginate.current > 1) {
                this.$router.push({ query: { page: this.infoPaginate.current } })
            } else {
                // Delete query params page
                this.$router.push({})
            }
        },
        next() {
            this.infoPaginate.current++
            window.scrollTo({ top: 0, behavior: 'smooth' });
            this.getCategories(this.infoPaginate.current, this.dateSelect)
            if (this.infoPaginate.current > 1) {
                this.$router.push({ query: { page: this.infoPaginate.current } })
            } else {
                this.$router.push({})
            }
        }
    }
}
</script>

<style></style>