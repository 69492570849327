<template>
    <!--Card-->
    <router-link :to="{ name: 'single.category', params: { categoryId: category.id } }"
        class="text-decoration-none text-dark ">
        <div class="card mb-3">
            <div class="card-body text-center">
                <div class="">
                    <img class="img-fluid" :src="category.imageUrl" style="max-height: 200px;" alt="200x200"
                        data-holder-rendered="true">
                </div>
                <h4 class="card-title mt-1 font-size-16">{{ category.title }} - {{ category.videos_count }}</h4>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: {
        category: Object,
    }
}
</script>
