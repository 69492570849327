<template>
    <div class="card mb-5">
        <div class="card-body">
            <h4 class="card-title">
                {{ video.title }}
            </h4>
        </div>
        <div class="ratio ratio-16x9">
            <iframe :src="video.videoUrl" :title="video.title" allowfullscreen allow="autoplay"></iframe>
        </div>
    </div>
    <div class="accordion accordion-flush" id="accordionFlushExample">
        <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
                <button class="accordion-button fw-medium collapsed" type="button" data-bs-toggle="collapse"
                    data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                    Description
                </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body text-muted">
                    <template v-if="video.stars && video.stars.length > 0">
                        <div class="my-1">
                            <span class="text-muted">Stars:</span>
                            <template v-for="star in video.stars" :key="star.id">
                                <router-link :to="{ name: 'single.star', params: { starId: star.id } }">
                                    <button type="button"
                                        class="btn btn-sm btn-soft-danger waves-effect waves-light mx-2 my-1">
                                        {{ star.name }}
                                    </button>
                                </router-link>
                            </template>
                        </div>
                    </template>
                    <template v-if="video.category_id && video.category_id.length > 0">
                        <div class="my-1">
                            <span class="text-muted">Categories:</span>
                            <template v-for="category in video.category_id" :key="category.id">
                                <router-link :to="{ name: 'single.category', params: { categoryId: category.id } }">
                                    <button type="button"
                                        class="btn btn-sm btn-soft-primary waves-effect waves-light mx-2 my-1">
                                        {{ category.title }}
                                    </button>
                                </router-link>
                            </template>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { usePreloader } from '../utilities/Preloader.vue';

export default {
    props: {
        video: {
            required: true,
            type: Object
        }
    },
    setup(props) {
        const { isLoading } = usePreloader();

        return {
            isLoading
        }
    }
}
</script>